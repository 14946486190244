import { useState, useRef, useEffect } from 'react';
import HeadphoneIcon from '../../../assets/images/headphone.png';
import MicIcon from '../../../assets/images/mic.png';
import PlusIcon from '../../../assets/images/plus-button.png';

import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Message } from '../../../models/chat';
import { fetchConversation, sendMessage } from '../../../clients/be';

const OpenAI = ({
  conversationId,
  openVoiceChat,
}: {
  openVoiceChat: () => void;
  conversationId: string;
}) => {
  const [isListening, setIsListening] = useState(false);

  const audioChunksRef = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const isIOS = useMediaQuery('(pointer: fine)');
  const client = useQueryClient();
  const [inputValue, setInputValue] = useState('');
  const chatRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isTextLoading, setIsTextLoading] = useState<boolean>(false);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  // useEffect(() => {
  //   if (messages) {
  //     setMessages([
  //       {
  //         role: 1,
  //         message:
  //           'مرحبا! أنا مساعد الصحة الخاص بك من وزارة الصحة في الكويت. كيف يمكنني مساعدتك اليوم؟\nHello! I am your healthcare assistant from the Ministry of Health in Kuwait. How may I assist you today?',
  //         timestamp: new Date().toISOString(),
  //       },
  //       ...messages,
  //     ]);
  //   }
  // }, [messages]);

  const sendMutation = useMutation({
    mutationFn: async ({ conversationId, textMessage }: any) => {
      const data = await sendMessage(conversationId, textMessage);
      return data;
    },
    mutationKey: ['send-message'],
    onSuccess: (data) => {
      console.log('textData ', data);
      if (typeof data === 'string') {
        setMessages([
          ...messages,
          {
            role: 1,
            message: data,
            timestamp: new Date().toISOString(),
          },
        ]);
      }
    },
    onMutate: () => {
      setIsTextLoading(true);
    },
    onSettled: () => {
      setIsTextLoading(false);
    },
  });

  const startListening = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
        audioChunksRef.current.push(event.data);
      });
      mediaRecorderRef.current.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/mp3; codecs=opus',
        });

        audioChunksRef.current = [];

        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.wav');

        fetch(
          `https://wh-dev-moh-kuw-va-na.azurewebsites.net/api/converter/stt?UserTrackedId=${conversationId}`,
          {
            method: 'POST',
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const transcription = data.transcription;

            setInputValue(transcription);
          })
          .catch((error) => {
            console.error('Error sending audio to API:', error);
          });
      });
      mediaRecorderRef.current.start();
      setIsListening(true);
    } catch (error) {
      alert('Error accessing microphone. Please check your audio permissions.');
      console.error('Error starting audio recording:', error);
    }
  };

  const stopListening = () => {
    setIsListening(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      console.log('stopped');
    }
  };

  // const botContainerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const botConnection = WebChat.createDirectLine({
  //     domain: 'https://europe.directline.botframework.com/v3/directline',
  //     secret: '-R8IWj-_WE8.9CgwT-Mz1Bivob9RvK0O-plEMsr4Zf-agOjQzBTNQco',
  //   });

  //   const styleOptions = {
  //     botAvatarImage:
  //       'https://cxuwebassets.blob.core.windows.net/webassets/MOJ/moh-bot-logo.png',
  //     hideSendBox: false,
  //     botAvatarInitials: 'HealthBot',
  //     userAvatarInitials: 'You',
  //     backgroundColor: '#F8F8F8',
  //     hideUploadButton: false,
  //   };

  //   const store = WebChat.createStore({}, function () {
  //     return function (next: any) {
  //       return function (action: any) {
  //         if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
  //           console.log('Incoming activity:', action.payload.activity);
  //           // You can process or handle the activity here
  //         }

  //         return next(action);
  //       };
  //     };
  //   });

  //   const webchatOptions = {
  //     directLine: botConnection,
  //     styleOptions,
  //     store,
  //     locale,
  //   };

  //   WebChat.renderWebChat(webchatOptions, botContainerRef.current);

  //   const inputField1 = document.querySelector(
  //     '.open-ai-bot .webchat__send-box-text-box__input'
  //   );
  //   const handleKeyPress = (event: KeyboardEvent) => {
  //     if (event.key === 'Enter' && inputField1) {
  //       event.preventDefault();
  //       const activity: any = {
  //         type: 'message',
  //         from: { id: conversationId, name: 'User' },
  //         text: inputField1 instanceof HTMLInputElement && inputField1.value,
  //       };

  //       botConnection.postActivity(activity).subscribe(
  //         () => console.log('Message sent'),
  //         (error) => console.error('Error sending message:', error)
  //       );

  //       if (inputField1 instanceof HTMLInputElement) {
  //         inputField1.value = '';
  //       }
  //     }
  //   };

  //   // Attach event listener to the input field
  //   if (inputField1 instanceof HTMLInputElement)
  //     inputField1?.addEventListener('keypress', handleKeyPress);

  //   return () => {
  //     if (inputField1 instanceof HTMLInputElement)
  //       inputField1?.removeEventListener('keypress', handleKeyPress);
  //   };
  // }, [locale]);

  return (
    <Flex h="full" w="full" p={2} pos="relative">
      <Box bg="white" borderRadius="lg" w="full">
        <Flex
          direction="column"
          h={{ base: isIOS ? '80vh' : '80vh', md: '520px' }}
          overflow="auto"
          ref={chatRef}
          fontSize="11px"
          pt={{ base: '7vh', md: '0' }}
        >
          {messages.map((message, idx) => (
            <Flex
              key={idx}
              direction={message.role === 0 ? 'row-reverse' : 'row'}
              align="start"
              mb={4}
            >
              {message.role !== 0 && (
                <Image src="./avatar-logo.png" me={2} w="25px" h="25px" />
              )}
              <Box
                bg={message.role === 0 ? '#F2F2F5' : ''}
                color="black"
                borderRadius="md"
                borderTopEndRadius="0"
                px={message.role === 0 ? 3 : 0}
                py={1}
                maxW={message.role === 0 ? '80%' : '100%'}
              >
                <Text whiteSpace="pre-line">{message.message}</Text>
              </Box>
            </Flex>
          ))}
          {isTextLoading && (
            <Flex direction="row" align="start" mb={4}>
              <Image src="./avatar-logo.png" me={2} w="25px" h="25px" />

              <Box py={1}>
                <Box className="loading-spinner">
                  <motion.div
                    className="dot"
                    animate={{
                      scale: [1, 2, 1],
                      opacity: [1, 0, 1],
                      borderRadius: ['50%', '50%', '50%'],
                    }}
                    transition={{
                      duration: 1,
                      repeat: Infinity,
                      repeatType: 'loop',
                      ease: 'linear',
                    }}
                  />
                </Box>
              </Box>
            </Flex>
          )}
          {messages.length === 0 && !isTextLoading && (
            <Image
              position="absolute"
              top="50%"
              left="50%"
              src="./logo-lg.png"
              w="60px"
              ms="-1.6rem"
              mt="-3.5rem"
            />
          )}
        </Flex>
        <Flex mt={4} position="relative">
          <Button
            variant="ghost"
            p="0"
            _hover={{
              bg: 'none',
            }}
          >
            <Image src={PlusIcon} w="33px" />
          </Button>
          <form
            className="flex w-full"
            onSubmit={(e) => {
              e.preventDefault();
              sendMutation.mutateAsync({
                conversationId,
                textMessage: inputValue,
              });
              setMessages([
                ...messages,
                {
                  role: 0,
                  message: inputValue,
                  timestamp: new Date().toISOString(),
                },
              ]);
              setInputValue('');
            }}
          >
            <Input
              height="33px"
              mt="4px"
              fontSize="11px"
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              mx={1}
              borderRadius="md"
              ps="2"
              isDisabled={isListening}
              pe="8"
              style={{
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            />
          </form>
          {/* Record the voice on click of this button and append the respond to the input field */}
          {isListening ? (
            <Button
              _hover={{
                bg: 'none',
              }}
              variant="ghost"
              p="0"
              position="absolute"
              right="2.6rem"
              onClick={stopListening}
              style={{
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
              zIndex={1}
            >
              <Image src={MicIcon} w="18px" />
            </Button>
          ) : (
            <Button
              _hover={{
                bg: 'none',
              }}
              variant="ghost"
              p="0"
              position="absolute"
              right="2.6rem"
              onClick={startListening}
              style={{
                userSelect: 'none',
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
              zIndex={1}
            >
              <Image src={MicIcon} w="18px" opacity="0.5" />
            </Button>
          )}

          <Button
            p="0"
            variant="ghost"
            _hover={{
              bg: 'none',
            }}
            style={{
              userSelect: 'none',
              WebkitTouchCallout: 'none',
              WebkitUserSelect: 'none',
              KhtmlUserSelect: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
            }}
            onClick={openVoiceChat}
          >
            <Image src={HeadphoneIcon} w="33px" />
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default OpenAI;
